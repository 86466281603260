<template>
  <WeContainer card="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="submitForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
    </div>
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <ul class="m-0">
                      <li>
                        Eklenen Resim Sayısı: <b>{{ data.pictures.length }}</b>
                        <small class="ml-2 text-muted"><i>Maks. 50</i></small>
                      </li>
                      <li>
                        Aktif Resim Sayısı: <b>{{ activePictures }}</b>
                        <small class="ml-2 text-muted"><i>Maks. 10</i></small>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-auto mt-2 mt-md-0">
                    <span
                      class="btn btn-outline-primary"
                      v-on:click="addPicture"
                    >
                      <i class="fas fa-plus">
                        <span class="ml-2"> Resim Alanı Ekle </span>
                      </i>
                    </span>
                  </div>
                </div>
                <hr />

                <h6>Resim Listesi</h6>
                <!-- Picture Section -->
                <div v-if="data.pictures.length">
                  <div class="row align-items-stretch">
                    <WePicture
                      v-for="(picture, i) in data.pictures"
                      v-bind:key="i"
                      v-bind:picture-index="i"
                      v-bind:pictures.sync="data.pictures"
                      v-bind:active-count="activePictures"
                      v-bind:active-limit="activeLimit"
                    />
                  </div>
                </div>
                <WeCard class="alert-primary" v-else>
                  <i class="fas fa-info-circle"></i> Resim Ekleyin
                </WeCard>
                <!-- Picture Section -->

                <h6 class="my-3">Slide Özellikleri</h6>
                <!-- Other Features -->
                <div class="font-14">
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="slide-show-time"
                        >Resim Görüntülenme Süresi :</label
                      >
                    </div>
                    <div class="col-lg-3">
                      <WeInput
                        type="text"
                        v-bind:formGroup="false"
                        id="slide-show-time"
                        v-model="data.showTime"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="slide-time">Resim Değişme Süresi :</label>
                    </div>
                    <div class="col-lg-3">
                      <WeInput
                        type="text"
                        v-bind:formGroup="false"
                        id="slide-time"
                        v-model="data.changeTime"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="title">Başlık :</label>
                    </div>
                    <div class="col-lg-3">
                      <WeInput
                        class="mb-0"
                        v-bind:small-size="true"
                        v-model="slideTitle"
                        v-bind:required="true"
                        v-bind:error="$v.slideTitle.$error"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="show-title">Başlık Gösterilsin mi? :</label>
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="show-title"
                          v-model="data.show.title"
                        />
                        <label
                          class="custom-control-label"
                          for="show-title"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <!-- Auto Start -->
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="auto-start">Otomatik Başlatılsın mı? :</label>
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="auto-start"
                          v-model="data.auto"
                        />
                        <label
                          class="custom-control-label"
                          for="auto-start"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <!-- Auto Start -->

                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="show-description"
                        >Açıklama Alanı Gösterilsin mi? :</label
                      >
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="show-description"
                          v-model="data.show.description"
                        />
                        <label
                          class="custom-control-label"
                          for="show-description"
                        ></label>
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label for="show-buttons"
                        >İleri-Geri Butonları Gösterilsin mi? :</label
                      >
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="show-buttons"
                          v-model="data.show.buttons"
                        />
                        <label
                          class="custom-control-label"
                          for="show-buttons"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label
                        >Yayınlanacağı Kategoriler:
                      </label>
                      <br /><span
                        ><small
                          >Eğer bir kategori seçilirse bu slider anasayfada
                          gösterilmez.</small
                        ></span
                      >
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <WeRowInput
                          form="vMultipleSelect"
                          size="lg"
                          :multiple="true"
                          labelClass="display-none"
                          inputClass="col-12 col-lg-12"
                          name="category"
                          placeholder="Kategori"
                          :expandLevel="'1'"
                          :flat="true"
                          v-bind:option-prop="categoryList"
                          v-model="data.categories"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label
                        >Yayınlanacağı Markalar:
                      </label>
                      <br /><span
                        ><small
                          >Eğer bir marka seçilirse bu slider anasayfada
                          gösterilmez.</small
                        ></span
                      >
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <WeRowInput
                          form="vMultipleSelect"
                          size="lg"
                          :multiple="true"
                          labelClass="display-none"
                          inputClass="col-12 col-lg-12"
                          placeholder="Marka"
                          :expandLevel="'1'"
                          :flat="true"
                          v-bind:option-prop="brandList"
                          v-model="data.brands"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-lg-2 font-weight-bold">
                      <label
                        >Yayınlanacağı Modeller:
                      </label>
                      <br /><span
                        ><small
                          >Eğer bir model seçilirse bu slider anasayfada
                          gösterilmez.</small
                        ></span
                      >
                    </div>
                    <div class="col-lg-3">
                      <div class="custom-control custom-switch">
                        <WeRowInput
                          form="vMultipleSelect"
                          size="lg"
                          :multiple="true"
                          labelClass="display-none"
                          inputClass="col-12 col-lg-12"
                          name="model"
                          placeholder="Model"
                          :expandLevel="'1'"
                          :flat="true"
                          v-bind:option-prop="modelList"
                          v-model="data.models"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Other Features -->
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
      pictureLimit: 50,
      activeLimit: 10,
      default: {
        title: null,
        imageData: null,
        desktop: { files: [], filesData: null },
        mobile: { files: [], filesData: null },
        orderNumber: 0,
        description: null,
        url: null,
        startDate: null,
        dueDate: null,
        newTab: false,
        active: true,
      },
      data: {
        pictures: [],
        title: null,
        categories: [],
        models: [],
        brands: [],
        show: {
          title: false,
          description: false,
          buttons: false,
        },
        auto: false,
        showTime: 4000,
        changeTime: 1000,
      },
    };
  },
  components: {
    Navigation,
  },
  validations: {
    "data.pictures": {
      required: function () {
        return this.data.pictures.length;
      },
    },
    slideTitle: {
      required,
    },
  },
  methods: {
    ...mapActions("slider", ["load", "create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateSlider();
      } else {
        this.addSlider();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Blog listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addSlider() {
      if (!this.validateForm()) {
        return;
      }

      this.create({
        data: this.data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateSlider() {
      if (!this.validateForm()) {
        return;
      }

      this.update({
        id: this.$route.params.id,
        data: this.data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getSliderById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.data = result;
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/sliders");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    addPicture() {
      if (
        this.data.pictures &&
        this.data.pictures.length >= this.pictureLimit
      ) {
        this.$swal({
          title: "Hata",
          text: "En fazla " + this.pictureLimit + " adet resim eklenebilir",
          icon: "error",
        });
      } else {
        const copy = helper.clone(this.default);
        copy.title = "Başlık " + parseInt(this.data.pictures.length + 1);
        copy.description =
          "Açıklama " + parseInt(this.data.pictures.length + 1);
        copy.orderNumber = this.data.pictures.length + 1;
        this.data.pictures.push(copy);
        if (this.activePictures >= this.activeLimit) {
          this.default.active = false;
        }
      }
    },
    sortPictures() {
      if (this.data.pictures && this.data.pictures.length) {
        this.data.pictures.sort((a, b) => a.orderNumber - b.orderNumber);
      }
    },
  },
  computed: {
    ...mapState(["shared", "slider"]),
    ...mapState({
      categories: (state) => state.category.list,
      models: (state) => state.model.list,
      brands: (state) => state.brand.list,
    }),
    categoryList() {
      return this.categories;
    },
    modelList() {
      return this.models;
    },
    brandList() {
      return this.brands;
    },
    getTitle() {
      return this.$route.params.id ? "SLIDE DÜZENLE" : "SLIDE EKLE";
    },
    activePictures() {
      let actives = this.data.pictures.filter((p) => p.active === true);
      return actives.length;
    },
    slideTitle: {
      get() {
        return this.data.title;
      },
      set(value) {
        this.data.title = value;
      },
    },
  },
  mounted() {
    this.getSliderById();
    this.sortPictures();
    this.$store.dispatch("category/getList", "");
    this.$store.dispatch("model/getList", "");
    this.$store.dispatch("brand/getList", "");
  },
  watch: {
    "slider.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getSliderById();
        }
      },
    },
  },
};
</script>
<style lang="scss">
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
vue-treeselect__menu {
  z-index: 9999;
}
</style>
